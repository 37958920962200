import Keycloak from 'keycloak-js'
 
// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'

const keycloakConfig = {
    url: 'https://auth.nanominds.io:9443/auth',
    realm: 'jhipster',
    clientId: 'ndp-scratch'
}

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
