// import React from 'react'

// export default function Home() {
//     return (
//         <div>
//             Home Page
//         </div>
//     )
// }

import React from "react";

import { Link } from "react-router-dom";
import HeroBanner from "../components/landing/HeroBanner.jsx";

import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";

import { Box } from '@chakra-ui/react'
import LandingPage from "../components/landing/LandingPage.jsx";

const Home = () => {
    return (
        <React.Fragment>
            <ChakraProvider theme={theme}>
                {/* <HeroBanner /> */}

                <LandingPage/>

                {/* <Box fontSize={['sm', 'md', 'lg', 'xl']}>Font Size</Box> */}

                {/* <Link to="/app" replace>Scratch GUI </Link> */}
            </ChakraProvider>
            {/* <Outlet /> */}

            {/* <p className={styles.test}> Test</p> */}
        </React.Fragment>
    );
};
export default Home;
