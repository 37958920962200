import React from "react";

import AppStateHOC from "../lib/app-state-hoc.jsx";
import GUI from "../containers/gui.jsx";
import HashParserHOC from "../lib/hash-parser-hoc.jsx";
import { compose } from "redux";

import { useKeycloak } from "@react-keycloak/web";

const ScratchGui = () => {
    // console.log("Test");

    // const appTarget = document.createElement("div");
    // appTarget.className = styles.main;
    // // document.body.appendChild(appTarget);
    const appTarget = document.getElementById('main')
    GUI.setAppElement(appTarget);

    const backpackHostMatches = window.location.href.match(
        /[?&]backpack_host=([^&]*)&?/
    );
    const backpackHost = backpackHostMatches ? backpackHostMatches[1] : null;

    const onClickLogo = () => {
        window.location = "https://build.nanominds.io";
    };

    const { keycloak, initialized } = useKeycloak();
    if (!initialized) return <p>Loading...</p>;
    if (!keycloak) return <p>Loading...</p>;

    if (initialized && !(keycloak && keycloak.authenticated)) {
        keycloak.login();
    }

    const WrappedGui = compose(AppStateHOC, HashParserHOC)(GUI);

    return (
        <React.Fragment>
            {keycloak.authenticated && 
            <WrappedGui
                canEditTitle
                backpackVisible
                showComingSoon
                backpackHost={backpackHost}
                canSave={false}
                onClickLogo={onClickLogo}
            />}
        </React.Fragment>
    );
};

export default ScratchGui;
