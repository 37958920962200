import React from "react";

import Home from "./Home.jsx";

import ScratchGui from "./ScratchGui.jsx";
// const ScratchGui = React.lazy(() => import("./ScratchGui.jsx"));

// import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
    BrowserRouter,
    Route,
    Switch,
    Navigate,
    Redirect,
} from "react-router-dom";

// import { Layout } from "./Layout.jsx";

import { useKeycloak } from "@react-keycloak/web";
import { PrivateRoute } from "./PrivateRoute.js";

// import './index.css';

export const AppRouter = () => {
    const [, initialized] = useKeycloak();
    if (!initialized) {
        return <h3>Loading ... !!!</h3>;
    }
    return (
        <>
            {/* <React.Suspense fallback={<span>Loading...</span>}> */}
                <BrowserRouter>
                    {/* <Link to="/">Home</Link>
                <Link to="/gui">GUI</Link> */}
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <PrivateRoute path="/gui" component={ScratchGui} />
                        <Route path="/app" component={ScratchGui} />
                    </Switch>
                </BrowserRouter>
            {/* </React.Suspense> */}
        </>
    );
};

// const AppRouter = () => {
//     return (
//         <BrowserRouter>
//             <Routes>
//                 <Route exact path="/" element={<Home />}/>
//                 <Route path="/gui" element={<ScratchGui/>} />
//             </Routes>
//         </BrowserRouter>
//     );

//     // const { initialized } = useKeycloak();

//     // if (!initialized) {
//     //     return <div>Loading ...</div>
//     // }

//     // // useEffect(() => {
//     // //     effect
//     // //     return () => {
//     // //         cleanup
//     // //     }
//     // // }, [input]);

//     // console.log({ routing });

//     return routing;

//     // return (
//     //     {routing}
//     //     // <Router>
//     //     //     <Routes>
//     //     //         <Route path="app" element={<DashboardLayout />} >
//     //     //             <Route path="account" element={<Account />} />
//     //     //             <Route path="customer" element={<CustomerListView />} />
//     //     //             <Route path="dashboard" element={<ProtectedRoute component={Dashboard} />} />
//     //     //             <Route path="products" element={<ProductList />} />
//     //     //             <Route path="settings" element={<Settings />} />
//     //     //             <Route path="*" element={<Navigate to="/404" />} />
//     //     //         </Route>

//     //     //         <Route path="/" element={MainLayout}>
//     //     //             <Route path="home" element={<HomeView />} />
//     //     //             <Route path="course/:id" element={<CourseDetailView />} />
//     //     //             <Route path="login" element={<Account />} />
//     //     //             <Route path="account" element={<Account />} />
//     //     //             <Route path="register" element={<Account />} />
//     //     //             <Route path="404" element={<NotFoundView />} />
//     //     //             <Route path="/" element={<Navigate to="/home" />} />
//     //     //             <Route path="*" element={<Navigate to="/404" />} />

//     //     //         </Route>

//     //     //     </Routes>

//     //     // </Router>
//     // );
// };

export default AppRouter;
