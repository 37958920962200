import React from 'react';

import {
    Flex,
    Container,
    Heading,
    Stack,
    Text,
    Button,
    Image,
    Icon,
  } from '@chakra-ui/react';

import {Link} from 'react-router-dom';


  
  export default function HeroBanner() {
    return (
      <Container maxW={'5xl'} centerContent>
        <Stack
          textAlign={'center'}
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}>
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '5xl' }}
            lineHeight={'110%'}>
            Nanominds Development Platform{' '}
          </Heading>
          <Text color={'gray.500'} maxW={'3xl'}>
            Building robots is made easy using Nanominds Development Platform (NDP).
          </Text>
          <Stack spacing={6} direction={'row'}>
            <Button
              rounded={'full'}
              px={6}
              colorScheme={'orange'}
              bg={'orange.400'}
              _hover={{ bg: 'primary.500' }}>
              <Link to="/gui">Launch NDP </Link>

             
            </Button>
            {/* <Button rounded={'full'} px={6}>
              Learn more
            </Button> */}
          </Stack>
          <Flex w={'full'}> 
            {/* <Illustration
              height={{ sm: '24rem', lg: '28rem' }}
              mt={{ base: 12, sm: 16 }}
            /> */}

            {/* <Image src='./block2.svg' alt='Banner Image' /> */}

          </Flex>
        </Stack>
      </Container>
    );
  }
  
  export const Illustration = (props) => {
    return (
      <Icon
        width="100%"
        viewBox="0 0 702 448"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
      </Icon>
    );
  };