

import React from 'react';
import Navbar from './Navbar.jsx'
import HeroBanner from './HeroBanner.jsx';

export default function LandingPage() {
    return (
        <div>
            <Navbar/>
            <HeroBanner/>
        </div>
    )
}
