

import React from "react";
import ReactDOM from "react-dom";


// import App from "./App.jsx"

import { AppRouter } from './AppRouter.jsx';

import keycloak from '../config/keycloak';

import { KeycloakProvider } from '@react-keycloak/web';

// import { ChakraProvider } from '@chakra-ui/react'
// import theme from './theme'


import './index.css';
// import './home.css';


// ReactDOM.render(
//   <App/>, document.getElementById("main")
// );


ReactDOM.render(
  <React.StrictMode>

    <React.Fragment>
      <KeycloakProvider keycloak={keycloak}>
          <AppRouter/>
      </KeycloakProvider>
    </React.Fragment>
    </React.StrictMode>,
  
    document.getElementById('main')
  );