exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "html {\n    width: 100%; \n    height: 100%;\n    margin: 0;\n\n}\nbody {\n    width: 100%; \n    height: 100%;\n    margin: 0;\n}\n.index_app_3Qs6X {\n    /* probably unecessary, transitional until layout is refactored */\n    width: 100%; \n    height: 100%;\n    margin: 0;\n\n    /* Setting min height/width makes the UI scroll below those sizes */\n    min-width: 1024px;\n    min-height: 640px; /* Min height to fit sprite/backdrop button */\n}\n#index_main_2wQUj {\n    width: 100%; \n    height: 100%;\n    margin: 0;  \n\n    /* Setting min height/width makes the UI scroll below those sizes */\n    min-width: 1024px;\n    min-height: 640px; \n}\n/* @todo: move globally? Safe / side FX, for blocks particularly? */\n* { -webkit-box-sizing: border-box; box-sizing: border-box; }\n.index_test_DBmFC {\n    color: orange;\n}\n/* @todo: move globally? Safe / side FX, for blocks particularly? */\n* { -webkit-box-sizing: border-box; box-sizing: border-box; }\n", ""]);

// exports
exports.locals = {
	"app": "index_app_3Qs6X",
	"main": "index_main_2wQUj",
	"test": "index_test_DBmFC"
};